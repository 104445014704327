body {
  background-color: #36393f;
  color: #dcddde;
  font-family: 'Whitney', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin: 0;
}

.container {
  max-width: 360px; /* Increased from 340px */
  background-color: #18191c;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,.2);
  margin: 0;
}

.banner {
  height: 120px;
  overflow: hidden;
}

.banner-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-picture-container {
  position: relative;
  margin-top: -40px;
  padding: 0 16px;
}

.profile-img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 6px solid #18191c;
  background-color: #18191c;
}

.content {
  padding: 16px;
}

.user-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 12px;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
}

.name-info {
  margin-bottom: 8px; /* Add some space below name info */
}

.display-name {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}

.username-pronouns {
  display: flex;
  align-items: center;
  margin-top: 4px;
}

.username {
  color: #b9bbbe;
  font-size: 14px;
}

.separator {
  color: #b9bbbe;
  font-size: 14px;
  margin: 0 4px;
}

.pronouns {
  color: #b9bbbe;
  font-size: 14px;
}

.badges {
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 16px;
  padding: 4px;
  margin-left: auto; /* Push badges to the right */
}

.badge {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  margin-right: 4px;
  cursor: pointer;
}

.badge:last-child {
  margin-right: 0;
}

.badge-icon {
  width: 1.3rem;
  height: 1.3rem;
  object-fit: contain;
}

.badge:not(.transparent-badge) {
  background-color: #5865F2;
}

.badge:not(.transparent-badge) .badge-icon {
  color: white;
}

.transparent-badge {
  background-color: transparent;
}

.connections-link {
	text-decoration: none;
	color: #dcddde;
}

.divider {
  height: 1px;
  background-color: #2f3136;
  margin: 12px 0;
}

.about-me, .connections {
  margin-top: 16px;
}

.about-me h3, .connections h3 {
  color: #b9bbbe;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.about-me p {
  font-size: 14px;
  line-height: 1.4;
  margin: 0;
}

.card {
  display: flex;
  align-items: center;
  background-color: #202225; /* Updated background color */
  border-radius: 4px;
  padding: 12px;
  margin-top: 8px;
}

.icon {
  width: 24px;
  height: 24px;
  color: #dcddde;
}

.platform {
  margin: 0 0 0 12px;
  flex-grow: 1;
  font-size: 14px;
}

.arrow {
  color: #dcddde;
  font-size: 18px;
  cursor: pointer;
  margin-left: 8px; /* Add some space to the left of the arrow */
}

@media only screen and (max-width: 380px) {
  .container {
    margin: 0;
    height: 100vh;
    max-width: 100%;
    border-radius: 0;
  }
}